import { Switch } from "@mui/material";
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  required,
  List,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useTranslate,
  AutocompleteInput,
  ReferenceInput,
  DeleteButton,
  NumberInput,
  WrapperField,
  useRecordContext,
  useUpdate,
  RadioButtonGroupInput,
  ImageInput,
  ImageField,
} from "react-admin";
import {
  CreateToolbar,
  TopBarActions,
  imageInputFormat,
} from "../../components";
import "../advertisements/index.scss";

const AdsImage = ({ source, basePath, ...props }) => {
  const record = useRecordContext();
  console.log("🚀 ~ AdsImage ~ record:", record);
  const url = "https://bulutbazar.oss-cn-qingdao.aliyuncs.com/" + record.image;

  return <img alt='' src={url} {...props} />;
};

const AdsStatus = ({ source, basePath, ...props }) => {
  const record = useRecordContext();
  const [update, { loading }] = useUpdate();
  return (
    <Switch
      {...props}
      checked={Number(record[`${source}`]) === 1}
      color='primary'
      disabled={loading}
      onChange={(event) => {
        update("elan", {
          id: record.id,
          data: { status: event.target.checked ? 1 : 0 },
          record,
        });
      }}
    />
  );
};

export const SplashAdsList = (props) => {
  const isSmall = false;

  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("advertisements.splash")}
      filter={{ filter: { usage: "splash" } }}
      className='ad-list'
    >
      {isSmall ? (
        <SimpleList
          primaryText={() => translate("advertisements.merchantName")}
          secondaryText={(record) =>
            `${record.merchant.zh_name} ${record.merchant.ug_name} `
          }
        />
      ) : (
        <Datagrid>
          <TextField label='#' source='id' />
          <WrapperField label={translate("advertisements.adsName")}>
            <TextField source='zh_title' />
            <TextField source='ug_title' />
          </WrapperField>
          <AdsImage
            className='ad-image'
            label={translate("advertisements.adsImage")}
          />
          <WrapperField
            label={translate("advertisements.merchantName")}
            source='merchant'
          >
            <TextField source='merchant.zh_name' />
            <TextField source='merchant.ug_name' />
          </WrapperField>
          <TextField
            label={translate("advertisements.adsOrder")}
            source='order'
          />
          <AdsStatus
            source='status'
            label={translate("advertisements.adsStatus")}
          />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      )}
    </List>
  );
};

const filterToQuery = (searchText) => ({ name: searchText });
const merchantLabel = (record) => `${record.zh_name} ${record.ug_name}`;

export const SplashAdsEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit
      {...props}
      title={translate("advertisements.editSplash")}
      actions={<TopBarActions />}
    >
      <SimpleForm>
        <TextInput
          source='usage'
          value={4}
          disabled
          style={{ display: "none" }}
          label={false}
        />
        <TextInput
          source='zh_title'
          label={translate("common.zhname")}
          validate={required()}
        />
        <TextInput
          source='ug_title'
          label={translate("common.ugname")}
          validate={required()}
        />
        <NumberInput
          validate={required()}
          source='order'
          label={translate("advertisements.adsOrder")}
        />
        <RadioButtonGroupInput
          source='status'
          label={translate("advertisements.adsStatus")}
          defaultValue={1}
          choices={[
            { id: 1, name: translate("advertisements.open") },
            { id: 0, name: translate("advertisements.close") },
          ]}
        />
        <ImageInput
          source='zh_image'
          label={translate("common.zhImage")}
          accept='image/*'
          format={imageInputFormat}
        >
          <ImageField source='src' />
        </ImageInput>
        <ImageInput
          source='ug_image'
          label={translate("common.ugImage")}
          accept='image/*'
          format={imageInputFormat}
        >
          <ImageField source='src' />
        </ImageInput>

        <ReferenceInput
          validate={required()}
          reference='merchant'
          source='merchant_id'
          filterToQuery={filterToQuery}
          label={translate("advertisements.merchantName")}
        >
          <AutocompleteInput
            label={translate("advertisements.merchantName")}
            optionText={merchantLabel}
            optionValue='id'
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export const SplashAdsCreate = (props) => {
  const translate = useTranslate();

  return (
    <Create
      {...props}
      title={translate("advertisements.addSplash")}
      actions={<TopBarActions />}
    >
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput
          source='usage'
          value={4}
          disabled
          style={{ display: "none" }}
          label={false}
        />

        <TextInput
          source='zh_title'
          label={translate("common.zhname")}
          validate={required()}
        />
        <TextInput
          source='ug_title'
          label={translate("common.ugname")}
          validate={required()}
        />
        <NumberInput
          validate={required()}
          source='order'
          label={translate("advertisements.adsOrder")}
        />
        <RadioButtonGroupInput
          source='status'
          label={translate("advertisements.adsStatus")}
          defaultValue={1}
          choices={[
            { id: 1, name: translate("advertisements.open") },
            { id: 0, name: translate("advertisements.close") },
          ]}
        />
        <ImageInput
          source='zh_image'
          label={translate("common.zhImage")}
          accept='image/*'
          format={imageInputFormat}
        >
          <ImageField source='src' />
        </ImageInput>
        <ImageInput
          source='ug_image'
          label={translate("common.ugImage")}
          accept='image/*'
          format={imageInputFormat}
        >
          <ImageField source='src' />
        </ImageInput>

        <ReferenceInput
          validate={required()}
          reference='merchant'
          source='merchant_id'
          filterToQuery={filterToQuery}
          label={translate("advertisements.merchantName")}
        >
          <AutocompleteInput
            label={translate("advertisements.merchantName")}
            optionText={merchantLabel}
            optionValue='id'
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
